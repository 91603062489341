<!--  -->
<template>
  <el-dialog title="快照查看"
             :visible.sync="dialog"
             fullscreen
             append-to-body
             @close="close">
    <div class="snapshort-bg"
         id="snapshort">
      <template v-if="dataList && dataList.length>0">
        <div class="version">
          <span class="title">快照对比</span>
          <el-select v-model="leftId"
                     style="width:250px;"
                     placeholder="请选择快照"
                     size="small"
                     @change="leftChoise">
            <el-option v-for="item in dataList"
                       :key="item.id"
                       :label="item.title"
                       :value="item.id">
              <span style="float: left">{{ item.title }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px;margin-left:10px;"
                    @click="delSnapshot(item.id)"><i class="el-icon-delete"></i></span>
            </el-option>
          </el-select>
          <el-select style="margin-left:10px;width:250px;"
                     v-model="rightId"
                     placeholder="请选择快照"
                     size="small"
                     @change="rightChoise">
            <el-option label="当前版本"
                       value="main">
            </el-option>
            <el-option v-for="item in dataList"
                       :key="item.id"
                       :label="item.title"
                       :value="item.id">
            </el-option>

            <el-option label="无"
                       value="null">
            </el-option>
          </el-select>
        </div>
        <div class="snapshot-box"
             v-loading.fullscreen.lock="loading">
          <div class="left-gantt"
               v-if="leftData">
            <gantt viewTitle="a"
                   plugType="gantt"
                   dataType="task"
                   idTag="left"
                   :displayToolbar="false"
                   :viewData="leftData"
                   :cellHeight="34"
                   :disabled="true"
                   :disabledEditGroup="true"
                   :disabledAddTask="true"
                   :disabledEditTaskState="true"
                   :disabledEditTask="true"
                   :disabledDeleteTask="true"
                   :disabledEditTaskTitle="true"
                   @itemClick="clickTask">
            </gantt>
          </div>
          <!-- <div class="space"></div> -->
          <div class="right-gantt"
               v-if="rightData">
            <gantt ref="rightgantt"
                   viewTitle="a"
                   plugType="gantt"
                   dataType="task"
                   idTag="right"
                   :displayToolbar="false"
                   :viewData="rightData"
                   :cellHeight="34"
                   :disabled="true"
                   :disabledEditGroup="true"
                   :disabledAddTask="true"
                   :disabledEditTaskState="true"
                   :disabledEditTask="true"
                   :disabledDeleteTask="true"
                   :disabledEditTaskTitle="true">
            </gantt>
          </div>
        </div>
      </template>
      <div class="null-tip"
           v-else>{{loadMsg}}</div>
    </div>
  </el-dialog>
</template>
<style lang='less' scoped>
/deep/.el-dialog__body {
  box-sizing: border-box;
  padding: 10px;
}
/deep/.el-dialog__header {
  padding: 15px 10px 10px;
  border-bottom: 1px solid #f1f1f1;
}
/deep/.el-dialog__headerbtn {
  top: 4px;
  font-size: 35px;
}
/deep/svg {
  z-index: 999;
}
/deep/.aLabel {
  z-index: 1000;
  background-color: #f56c6c;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
}
.snapshort-bg {
  overflow: hidden;
}
.null-tip {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}
.version {
  .title {
    font-size: 15px;
    margin-right: 10px;
  }
}
.snapshot-box {
  margin-top: 10px;
  height: e('calc(100vh - 115px)');
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  position: relative;
  .left-gantt {
    position: relative;
    box-sizing: border-box;
    border: 1px solid #ccc;
    overflow: hidden;
    flex: 1;
  }
  .space {
    width: 30px;
    flex: none;
  }
  .right-gantt {
    position: relative;
    box-sizing: border-box;
    border: 1px solid #ccc;
    overflow: hidden;
    margin-left: -1px;
    flex: 1;
  }
}
</style>
<script>
import Gantt from '../../../components/Plug/Gantt/Gantt.vue'
export default {
  components: {
    Gantt
  },
  data () {
    return {
      dbConn: null,
      jsPlumbInstance: null,
      leftId: '',
      rightId: 'main',
      loadMsg: '正在加载快照...',
      loading: false,
      dialog: false,
      dataList: null,
      mainData: null,
      leftData: null,
      rightData: null,
      rightPlugId: '',
      rightViewId: '',
      plugId: '',
      viewId: ''
    }
  },
  computed: {

  },
  mounted () {
    //  this.getData()
    // this.open()

  },
  methods: {
    initPlumb () {
      const _this = this
      this.$nextTick(function() {
        _this.jsPlumbInstance = this.$jsPlumb.getInstance({
          Container: 'snapshort'
        })
        _this.jsPlumbInstance.reset()
      })
    },
    clickTask (index, item) {
      //  console.log(index)
      this.$nextTick(function() {
        const sourceId = 'leftprocon' + item.id
        const targetId = 'rightprocon' + item.id
        //  console.log(item.id)
        if (this.dbConn) {
          this.jsPlumbInstance.deleteConnection(this.dbConn)
          if (this.dbConn.id === sourceId) {
            this.jsPlumbInstance.repaintEverything()
            this.dbConn = null
            return
          }
        }
        if (this.rightData) {
          let rightTask = null
          for (let i = 0; i < this.rightData.length; i++) {
            for (let j = 0; j < this.rightData[i].pagelist.length; j++) {
              if (this.rightData[i].pagelist[j].id === item.id) {
                rightTask = this.rightData[i].pagelist[j]
                break
              }
            }
          }
          if (rightTask != null && rightTask.startdate && rightTask.enddate) {
            this.$refs.rightgantt.gridCellClick(0, rightTask)

            // const ele = document.getElementById(sourceId)
            // const left = ele.offsetLeft - 200
            // // const top = ele.parentNode.parentNode.parentNode.offsetTop
            // //  console.log(left, top)
            // this.$refs.rightgantt.scrollToPos(left, top)

            const sday = this.$helper.getDaysBetween(item.startdate, rightTask.startdate)
            let stext = '无变化'
            if (sday < 0) {
              stext = '提前' + Math.abs(sday) + '天'
            } else if (sday > 0) {
              stext = '延后' + Math.abs(sday) + '天'
            }
            const eday = this.$helper.getDaysBetween(item.enddate, rightTask.enddate)
            let etext = '无变化'
            if (eday < 0) {
              etext = '提前' + Math.abs(eday) + '天'
            } else if (eday > 0) {
              etext = '延后' + Math.abs(eday) + '天'
            }

            const clday = this.$helper.getDaysBetween(item.startdate, item.enddate)
            const crday = this.$helper.getDaysBetween(rightTask.startdate, rightTask.enddate)

            const cday = clday - crday
            let ctext = '无变化'
            if (cday < 0) {
              ctext = '增加' + Math.abs(cday) + '天'
            } else if (cday > 0) {
              ctext = '减少' + Math.abs(cday) + '天'
            }

            const text = '开始时间' + stext + '<br/>结束时间延' + etext + '<br/>整体时长' + ctext
            this.dbConn = this.connTask(sourceId, targetId, 'Top', 'Top', '#F56C6C', text)
            this.jsPlumbInstance.repaintEverything()
          }
        }
      })
    },
    connTask (sourceId, targetId, sourceAnchor, targetAnchor, color, text) {
      try {
        const conn = this.jsPlumbInstance.connect({
          source: sourceId,
          target: targetId,
          endpoint: 'Blank',
          connector: ['Flowchart', { cornerRadius: 4 }],
          anchors: [sourceAnchor, targetAnchor],
          paintStyle: { stroke: color, strokeWidth: 2 },
          overlays: [
            ['Arrow', { width: 6, length: 6, location: 1 }],
            ['Label', { // 连线上设置label
              location: 0.5,
              label: text, // label显示文字
              cssClass: 'aLabel'
              // events: { // label点击事件
              //   tap: function() { alert('hey') }
              // }
            }]
          ]
        })
        conn.id = sourceId
        return conn
      } catch (e) {
        // alert(e)
        return null
      }
    },
    open (plugId, viewId, mainData) {
      this.dialog = true
      const _this = this
      _this.$EventBus.$off('ganttscroll')
      _this.$EventBus.$on('ganttscroll', target => {
        setTimeout(() => {
          _this.jsPlumbInstance.repaintEverything()
        }, 1)
      })
      setTimeout(() => {
        _this.initPlumb()
        _this.plugId = plugId
        _this.viewId = viewId
        _this.mainData = mainData
        _this.getData()
      }, 0)
    },
    leftChoise (val) {
      this.leftData = []
      const _this = this
      setTimeout(() => {
        this.loading = true
        _this.leftData = _this.choise(val)
        _this.dbConn = null
        _this.jsPlumbInstance.reset()
        _this.loading = false
      }, 0)
    },
    rightChoise (val) {
      this.loading = true
      this.rightData = []
      const _this = this
      setTimeout(() => {
        if (val === 'null') {
          _this.rightData = null
          _this.rightPlugId = ''
          _this.rightViewId = ''
        } else if (val === 'main') {
          _this.rightData = _this.mainData
        } else {
          _this.rightPlugId = ''
          _this.rightViewId = ''
          _this.rightData = _this.choise(val)
        }
        _this.dbConn = null
        _this.jsPlumbInstance.reset()
        _this.loading = false
      }, 0)
    },
    choise (val) {
      let result = []
      for (let i = 0; i < this.dataList.length; i++) {
        if (this.dataList[i].id === val) {
          if (this.dataList[i].content) {
            result = JSON.parse(this.dataList[i].content)
            break
          }
        }
      }
      return result
    },
    close () {
      this.dbConn = null
      this.dataList = null
      this.leftData = null
      this.rightData = null
      this.rightPlugId = ''
      this.rightViewId = ''
      this.leftId = ''
      this.rightId = 'main'
      this.loadMsg = '正在加载快照...'
      if (this.jsPlumbInstance) {
        this.jsPlumbInstance.reset()
      }
    },
    getData () {
      this.$serviceApi.getSnapshotList({ viewid: this.viewId }).then(res => {
        if (res.code === 0) {
          this.dataList = res.data
          if (res.data.length > 0) {
            this.leftId = res.data[0].id
            this.leftChoise(this.leftId)
            this.rightChoise('main')
          } else {
            this.loadMsg = '还未创建快照!'
          }
        }
      })
    },
    delSnapshot (id) {
      this.$alert('确定删除此快照吗?', '提示', {
        confirmButtonText: '确定',
        callback: action => {
          if (action === 'confirm') {
            const data = {
              id: id
            }
            this.$serviceApi.deleteSnapshot(data).then(res => {
              if (res.code === 0) {
                this.getData()
                this.rightChoise('main')
              }
            })
          }
        }
      })
    },
    ganttLoad (data) {
      // this.rightData = data
    }
  }
}
</script>
